import React, {useState} from 'react';
import { Layout, Menu, Avatar, Dropdown, Button } from 'antd';
import { UserOutlined, LogoutOutlined, MessageOutlined, BranchesOutlined, PicRightOutlined,FundViewOutlined, WindowsOutlined, SettingOutlined, MenuOutlined , PartitionOutlined, DownOutlined, NodeExpandOutlined} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
const { REACT_APP_BASE_API_URI } = process.env;
const { Header, Content, Sider } = Layout;

const DashboardLayout = ({ children, userName, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();  // Get the current location
  const [collapsed, setCollapsed] = useState(true);

  const handleClick = (e) => {
    if (e.key.includes("http")) {
      window.location.href = e.key;
    }
    else {
      navigate(e.key);
    }
  };

  const baseApiUri = process.env.REACT_APP_BASE_API_URI;

  const handleLogout = () => {
    navigate(`${baseApiUri}`);
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ padding: '0 16px', background: 'rgb(0 21 41)', display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MenuOutlined
              onClick={toggleSidebar}
              style={{
                color: 'rgb(166 173 180)',
                fontSize: '24px',
                cursor: 'pointer',
                marginRight: '16px',
              }}
            />
            <div style={{ fontSize: '15px', color: 'rgb(166 173 180)', fontWeight: 'bold' }}>First Outcomes</div>
            {location.pathname === '/WorkFlowManagement' && (
            //   <Dropdown overlay={clientList}
            //     trigger={['click']}>
            //     <Button
            //       style={{
            //         marginLeft: '20px',
            //         backgroundColor: 'rgb(224 215 215)',
            //         color: 'white',
            //         border: 'none',
            //         padding: '13px',
            //         fontSize: '14px',
            //         fontWeight: '500',
            //         textAlign: 'left',
            //       }}
            //     >
            //       {selectedClient ? selectedClient : 'Select Client'} <DownOutlined />
            //     </Button>
            // </Dropdown>
            <></>
        )}
          </div>

          {/* User avatar and logout dropdown */}
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Avatar icon={<UserOutlined />} style={{
                background: 'rgb(166 173 180)',
                color : 'rgb(0 21 41)',
                cursor: 'pointer'}}/> {userName}
              </span>
            </Dropdown>
          </div>
        </Header>

        <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} style={{ top: "0" }}>
        {/* <div className="logo" style={{ fontSize: '15px', height: '32px', margin: '16px', color: '#fff' }}>First Outcomes</div> */}
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}  // Auto select based on current path
        >
          {/* <Menu.Item key="/PossibleResponseGroup" icon={<MessageOutlined />} onClick={handleClick}>
            Possible Response
          </Menu.Item>
          <Menu.Item key="/MetaDataGroup" icon={<WindowsOutlined />} onClick={handleClick}>
            Metadata Group
          </Menu.Item>
          <Menu.Item key='/Configuration' icon={<SettingOutlined />} onClick={handleClick}>
          Configuration
          </Menu.Item>
          <Menu.Item key="/WorkFlowComponent" icon={<PicRightOutlined />} onClick={handleClick}>
            Component Builder
          </Menu.Item>
          <Menu.Item key='/WorkFlow' icon={<BranchesOutlined />} onClick={handleClick}>
            Workflow
          </Menu.Item>
          <Menu.Item key='/UploadWorkflow' icon={<BranchesOutlined />} onClick={handleClick}>
            Workflow Upload
          </Menu.Item> */}
          <Menu.Item key='/WorkFlowManagement' icon={<NodeExpandOutlined />} onClick={handleClick}>
            Outbound 
          </Menu.Item>
          <Menu.Item key='/PatientView' icon={<FundViewOutlined />} onClick={handleClick}>
            Patient View
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        {/* <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ marginLeft: '47px' }}>Admin Dashboard</div>
          <div style={{ marginRight: '16px' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Avatar icon={<UserOutlined />} focus /> {userName}
              </span>
            </Dropdown>
          </div>
        </Header> */}
        <Content style={{ overflow: 'initial' }}>
          <div style={{  background: '#fff', minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
    </Layout>
  );
};

export default DashboardLayout;
