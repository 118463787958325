import React, { useState, useEffect } from 'react';
import { Select, Button, Table, Spin, message, Input, Form, Space, Popover, Tooltip, Modal, Row, Col, Typography, Card  } from 'antd';
import {getPatientList, getPatientinfo, getConvRecordingsAndMessages} from '../../../services/workFlowManagementService'
import '../../../../src/assets/styles/patientView.css';
import { EyeFilled, FileExcelFilled, FileTextFilled, FileTextOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons';
import ConversationModal from '../../components/uploadWorkflow/conversationModal';

const tailLayout = {
  wrapperCol: {offset: 6, span: 16}
};

const { Text, Title } = Typography;

const data = [
  {"id": 36795, "patientName": "Srivathsan Yytest", "identification": "93544786", "age": 25, "phone": "4153086769"},
  {"id": 36950, "patientName": "Ashwin Yytest", "identification": "93545053", "age": 22, "phone": "4155690596"}
];


const dynamicExtractPatientData = (data) => {
  return data.map(patient => {
      const patientFields = Object.keys(patient.patientData);
      let extractedData = { id: patient.patientData.identification };
      patientFields.forEach(field => {
          extractedData[field] = patient.patientData[field];
      });
      return extractedData;
  });
};


const PatientView = () => {
  const [patientId, setPatientId] = useState([]);
  const [patientDetails, setPatientDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [patientFilterForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [payload, setPayload] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isConversationModalVisible, setIsConversationModalVisible] = useState(false);
  const [conversationData, setConversationData] = useState([]);
  const [patientinfo, setPatientInfo] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [resetFlag, setResetFlag] = useState(false);

  useEffect(()=>{
    fetchPatientDetails();
  }, [])

  useEffect(() => {
    setFilteredData(patientDetails);
  }, [resetFlag]);

  const patientColumns =patientinfo.length > 0 ?
   Object.keys(patientinfo[0]).map(key => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    dataIndex: key,
    key,
    className :'expanded-table-header'
  })):[];

  const handleSearch = async (value) => {
    try {
      if (value.length > 2) {
        const result =await getPatientList(value);
        setOptions(JSON.parse(result.data) || []);
      }
    } catch (error) {
      message.error('Error fetching patient data');
    }
  };

  const filteredPayload = payload? Object.entries(payload).filter(([key, value]) =>
      key.toLowerCase().includes(searchText) ||
      (value !== null && value.toString().toLowerCase().includes(searchText))
    )
  : [];

  const handleSelect = (value) => {
    setPatientId((prevSelected) => {
      const newSelected = Array.isArray(value) ? value : [value];
      return [...new Set([...prevSelected, ...newSelected])];
    });
  };

  const handleDeselect = (value) => {
    setPatientId((prevSelected) => prevSelected.filter(id => id !== value));
  };

  const fetchPatientDetails = async () => {
    // if (!patientId) {
    //   message.error('Please select a patient');
    //   return;
    // }
    setLoading(true);
    try {
      const finalPatientId = patientId.length === 0 ? null : patientId; 

      const patientData = await getPatientinfo('2024-11-01', '2024-12-31', finalPatientId);
      const extractedPatientData = dynamicExtractPatientData(patientData);

      setPatientDetails(patientData);
      setFilteredData(patientData);
      setPatientInfo(extractedPatientData);

      const uniqueGroups = [
        ...new Set(patientData.flatMap(patient => patient.groupInfo).map(group => group.group_name))
      ];

      const uniqueProjects = [
        ...new Set(patientData.flatMap(patient => patient.groupInfo).map(project => project.project_name))
      ]
    
      setGroupOptions(uniqueGroups);
      setProjectOptions(uniqueProjects);
    } catch (error) {
      console.log(error);
      message.error('Error fetching patient details');
    } finally {
      setLoading(false);
    }
  };

  const handleActionClick = (record) => {
    const payload = JSON.parse(record.record_payload);
    setPayload(payload);
    setIsModalVisible(true); 
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setPayload(null);
    setSearchText('');
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const getOriginalBatchExcel = async (record) => {
    try {
            const link = document.createElement('a');
            link.href = process.env.REACT_APP_BOT_API_URI + "wfm/getBatchFile?fileName=" + record.file_name;
            link.download = record.fileName || 'download.xlsx';
            link.target = "_self";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    } catch (error) {
        console.error("Error while downloading the file:", error);
    }
}

const viewConversation = async (record) => {
  let conId = record?.conversation_id;
  if (conId) {
      // setLoading(true);
      try {
          let result = await getConvRecordingsAndMessages(conId);
          setConversationData({
              recordings: JSON.parse(result.recordings),
              messages: JSON.parse(result.conversationMessages)
          });
          setIsConversationModalVisible(true);
      } catch (error) {
          message.error('Error fetching conversation data');
      } finally {
          // setLoading(false);
      }
  }else {
      Modal.info({ title: 'Info', content: 'Conversation ID not found.' });
  }
}

const closeModal = () => {
  setIsConversationModalVisible(false);
}

  const expandedTable = (record) => {
    if (record.batches) {
      return (
        <>
              <Table
                columns={[
                  { title: 'Batch Name', dataIndex: 'batch_name', key: 'batch_name', className : 'batch-table-header' },
                  { title: 'Batch File',
                     dataIndex: 'file_name',
                     key: 'file_name' ,
                     className : 'batch-table-header',
                     render: (text, record) => (
                        <>
                          {/* <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                getOriginalBatchExcel(record);
                              }}
                              className='anchor-link'
                            > */}
                              {text || 'No File'}
                            {/* </a> */}
                        </>
                     )
                  },
                  { title: 'Conversation Id', dataIndex: 'conversation_id', key: 'conversation_id' , className : 'batch-table-header'},
                  { title: 'Call Status', dataIndex: 'call_status', key: 'call_status' , className : 'batch-table-header'},
                  {title: 'Action',
                    dataIndex: 'action',
                    key: 'action' ,
                    className : 'batch-table-header', 
                    align : 'center',
                    render : (text, record) => (
                      <>
                        <Tooltip title="View Payload">
                            <Button
                                type="link"
                                onClick={() => handleActionClick(record)}
                                icon={<FileTextFilled style={{ color: "#1890ff" }}/>}
                            >
                            </Button>
                        </Tooltip>
                        <Tooltip title="View Conversation">
                            <Button
                                type="link"
                                onClick={() => viewConversation(record)}
                            >
                                <EyeFilled />
                            </Button>
                        </Tooltip>
                    </>
                  ) }
                ]}
                dataSource={record.batches || []}
                pagination={false}
                rowKey="batch_id"
                bordered
                size='small'
                className='expandedTable'
              />
        <Modal
            title="Payload Details"
            open={isModalVisible}
            onCancel={handleCloseModal}
            footer={[<Button key="close" onClick={handleCloseModal}>Close</Button>]}
          >
          {payload ? (
          <div>
            <Input
              placeholder="Search keys or values"
              prefix={<SearchOutlined />}
              onChange={handleSearchChange}
              value={searchText}
              style={{ marginBottom: '16px' }}
            />

            <div style={{maxHeight: '400px', overflowY: 'auto', padding: '8px', background: '#f9f9f9', borderRadius: '8px'}}>
              <Row gutter={[16, 8]}>
                {filteredPayload.length > 0 ? (
                  filteredPayload.map(([key, value]) => (
                    <React.Fragment key={key}>
                      <Col span={10}>
                        <Text strong>{key}:</Text>
                      </Col>
                      <Col span={14}>
                        <Text>{value !== null && value !== undefined ? value.toString() : 'N/A'}</Text>
                      </Col>
                    </React.Fragment>
                  ))
                ) : (
                  <Text type="secondary">No matching results</Text>
                )}
              </Row>
            </div>
          </div>
        ) : (
          <p>No data available</p>
        )}
        </Modal>

        <ConversationModal 
                isModalOpen ={isConversationModalVisible} 
                onCloseModal ={closeModal} 
                conversationRecording ={conversationData.recordings || []}
                loadingConversation ={loading} 
                conversationMessages={conversationData.messages || []} 
            />
      </>
      );
    }

    if (record.payloads) {
      return (
        <Table
          columns={[
            { title: 'Payload ID', dataIndex: 'payload_id', key: 'payload_id' },
            { title: 'Conversation ID', dataIndex: 'conversation_id', key: 'conversation_id' },
            { title: 'Call Status', dataIndex: 'call_status', key: 'call_status' },
          ]}
          dataSource={record.payloads || []}
          pagination={false}
          rowKey="payload_id"
        />
      );
    }
    return null;
  };

  const getOriginalProjectExcel = async (record) => {
    try {
        if (record.project_file_name){
            const link = document.createElement('a');
            link.href = process.env.REACT_APP_BOT_API_URI + "wfm/getProjectFile?fileName=" + record.project_file_name;
            link.download = record.project_file_name || 'download.xlsx';
            link.target = "_self";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else{
            message.error('Project File Unavailable')
        }
    } catch (error) {
        console.error("Error while downloading the file:", error);
    }
}

  const columns = [
    { title: 'Project Name', dataIndex: 'project_name', key: 'project_name' , className : 'expanded-table-header', render: (text, record) => `${text} - ${record.group_name}`},
    { title: 'Project File', dataIndex: 'project_file_name', key: 'project_file_name' , className : 'expanded-table-header', 
      render: (text, record) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            getOriginalProjectExcel(record);
          }}
          className='anchor-link'
        >
          {text || 'No File'}
        </a>
      )
    },
  ];

  const onFilterClick = async () => {
    setFilterVisible(prevFilterVisible => !prevFilterVisible);
  }

const onFilterClose = async () => {
  setFilterVisible(false);
}

const onFilterReset = async () => {
  patientFilterForm.resetFields();
  setFilteredData(patientDetails); 
  setResetFlag(prev => !prev);
};

const handleSearchFilterSubmit = async () => {
  const values = patientFilterForm.getFieldsValue(); 
  const { Group, Project } = values;

  const filtered = patientDetails.filter(item => {
    const matchesGroup = Group && Group.length > 0 ? Group.includes(item.group_name) : true;
    const matchesProject = Project && Project.length > 0 ? Project.includes(item.project_name) : true;
    return matchesGroup && matchesProject;
  });

  setFilteredData(filtered); 
  setFilterVisible(false); 
}

const popoverContent  = (
  <div className='filterForm'>
       <Form
          name="basic" labelCol={{span: 5}} wrapperCol={{span: 18}}
          id="frmMessageFilter"
          form={patientFilterForm} onFinish={handleSearchFilterSubmit}
          style={{display: 'flex', flexDirection: 'column'}}
      >
           <Form.Item label="Group" name="Group">
               <Select
                  mode="multiple"
                  allowClear
                  // filterOption={false}
                  notFoundContent={null}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().includes(input.toLowerCase());
                  }}
                  defaultActiveFirstOption={false}
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder="Group"
                  maxTagCount={2}
                  maxTagTextLength={10}
              >
                  {groupOptions.map((group) => (
                    <Select.Option key={group} value={group}>
                      {group}
                    </Select.Option>
                  ))}
               </Select>
           </Form.Item>
           <Form.Item label="Project" name="Project">
               <Select
                  mode="multiple"
                  allowClear
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().includes(input.toLowerCase());
                  }}
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  placeholder="Project"
                  maxTagCount={2}
                  maxTagTextLength={10}
              >
                  {projectOptions.map((project) => (
                    <Select.Option key={project} value={project}>
                      {project}
                    </Select.Option>
                  ))}
               </Select>
           </Form.Item>
          <Form.Item {...tailLayout}>
              <Space>
                  <Button type="primary" htmlType="submit">Apply</Button>
                  <Button htmlType="button" onClick={(e) => onFilterReset(e)}>Reset</Button>
                  <Button type="primary" onClick={() => onFilterClose()} className="custom-danger">Close</Button>
              </Space>
          </Form.Item>
      </Form>
  </div>
);

const expandedGroupInfo = (record) => {
  const relatedGroupInfo = filteredData.find((pat) => pat.PatientId === record.id)?.groupInfo || [];
return(
  <>
        <Table
          dataSource={relatedGroupInfo}
          // dataSource={record.groupInfo}
          columns={columns}
          rowKey={(record, index) => index}
          // rowKey={(record) => record.key}
          style={{width: '100%', marginTop: '1%', overflowY: 'auto'}}
            className="reportTable"
            pagination={false}
          bordered
          expandable={{
            expandedRowRender : expandedTable,
          }}
        />
  </>
)
}

  return (
    <div style={{ padding: '20px' }}>
      <div style={{display : 'flex',  justifyContent: 'space-between', alignItems: 'center'}}>
        <div>
          <Select
            mode="multiple"
            showSearch
            placeholder="Search Patient"
            onSearch={handleSearch}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            notFoundContent={null}
            defaultActiveFirstOption={false}
            filterOption={false}
            style={{ width: '300px', marginRight: '10px' }}
            value={patientId}
          >
          {options.map(opt => (
              <Select.Option key={opt.patientId} value={opt.patientId}>{`${opt.firstName} ${opt.lastName}`}</Select.Option>
          ))}
          </Select>
          <Button onClick={fetchPatientDetails} loading={loading}>
            Search
          </Button>
        </div>
        <div>
        {/* <Tooltip title="Download Report">
              <Button size='large' type = 'link' style={{marginLeft : 3}} icon={<FileExcelFilled style={{marginTop : 3 , color : 'green', fontSize: '24px'}}/>}></Button>
          </Tooltip> */}
            <Space>
                <Popover className='escalation-margin' placement="bottomRight" content={popoverContent}
                         autoAdjustOverflow={false}
                         trigger="click" open={filterVisible} mask={true}
                         overlayStyle={{
                             width: '400px',
                             overflow: 'auto',
                             boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.175)'
                         }}>
                    <Tooltip placement="bottomRight" title='Patient Filter'>
                        <Button className='filter-button' type="primary"
                                icon={<FilterFilled/>} onClick={() => onFilterClick()}
                        >
                        </Button>
                    </Tooltip>
                </Popover>
            </Space>
        </div>
      </div>

      {/* {patientDetails && (
        <Card
        style={{
          marginTop:'10px',
          marginBottom: '16px',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgb(244 244 244)',
          padding: '8px 16px',
        }}
        bodyStyle={{ padding: 0 }}
      >
        <Row gutter={[8, 8]} wrap align="middle">
          <Col xs={24} sm={12} md={8} lg={6}>
            <Text strong style={{ color: 'rgb(39 37 33)', marginRight: '8px' }}>
              Patient Name:
            </Text>
            <Text style={{fontSize : 14, fontWeight : 600}}>{patientinfo?.name || 'N/A'}</Text>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Text strong style={{ color: 'rgb(39 37 33)', marginRight: '8px' }}>
              Phone Number:
            </Text>
            <Text style={{fontSize : 14, fontWeight : 600}}> {patientinfo?.phone ? `+1${patientinfo.phone}` : 'N/A'}</Text>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Text strong style={{ color: 'rgb(39 37 33)', marginRight: '8px' }}>
              Identification:
            </Text>
            <Text style={{fontSize : 14, fontWeight : 600}}>{patientinfo?.identification || 'N/A'}</Text>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Text strong style={{ color: 'rgb(39 37 33)', marginRight: '8px' }}>
              Age:
            </Text>
            <Text style={{fontSize : 14, fontWeight : 600}}>{patientinfo?.age || 'N/A'}</Text>
          </Col>
        </Row>
      </Card>      
      )} */}

    {patientinfo &&
      <Table dataSource={patientinfo} columns={patientColumns} 
      rowKey="identification" 
      style={{marginTop : 10}} 
      className="reportTable" 
      bordered
      pagination={false}
      expandable={{
        expandedRowRender : expandedGroupInfo,
        // rowExpandable : 
      }}
      />
    }

    </div>
  );
};

export default PatientView;
