import React, { useState, useEffect , useRef} from 'react';
import { Layout, Dropdown, Button, Menu, Collapse , Select, Modal, Input ,Form,Upload, Space, Table, message} from 'antd';
import { DownOutlined,EditOutlined,  PlusOutlined,DeleteOutlined,  UploadOutlined,  MinusOutlined, EditFilled, LoadingOutlined} from '@ant-design/icons';
import '../../../../src/assets/styles/workFlowManagement.css';
import {getClients, getClientInfo, processAddGroup, processUpdateGroup, uploadProjectFile, processAddProject} from '../../../services/workFlowManagementService'
import BatchInfo from './BatchInfo';
const { REACT_APP_BASE_API_URI } = process.env;

const { Sider, Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;


const WorkFlowManagement = () => {
  const [clientData, setClientData] = useState(null); 
  const [selectedClient, setSelectedClient] = useState(1); 
  const [selectedGroup, setSelectedGroup] = useState(null); 
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectId,setSelectedProjectId] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [menuItems, setMenuItems] = useState([]); 
  const [clientList, setClientList] = useState([]);
  const [groupForm] = Form.useForm(); 
  const [projectForm] = Form.useForm();
  const [groups, setGroups] = useState([]);
  const [editingGroup, setEditingGroup] = useState(null);
  const [addGroupName, setAddGroupName] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [batchVisible, setBatchVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] =useState(null);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, group: null });

  useEffect(() => {
    fetchClientList();
  }, []);

  useEffect(() => {
    if (editingGroup) {
      groupForm.setFieldsValue({ groupName: editingGroup.groupName });
    }
  }, [editingGroup]);

  useEffect(()=>{

    if (selectedProject){
      const updatedMenuItems = selectedProject.menuHeaders.map((header) => {
      const Component = componentMap[header.key];
      const projectContent = Component ? Component() : null;
        
      return {
          key: header.key,
          label: header.label,
          content: projectContent,
        };
      });

      setMenuItems(updatedMenuItems);
    
      if (updatedMenuItems.length > 0) {
        setSelectedMenuItem(updatedMenuItems[0]);
      }
    }
  }, [selectedProjectId, selectedProject, batchVisible, selectedRowKeys]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (contextMenu.visible && !e.target.closest('.context-menu')) {
        setContextMenu((prevContext) => ({ ...prevContext, visible: false }));
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenu.visible]);
  
  const componentMap = {
    batchinfo: (props) => (
      <BatchInfo
        projectId={selectedProjectId}
        project = {selectedProject}
        batchVisible={batchVisible}
        setBatchVisible={setBatchVisible}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    ),
    workflow: (props) => (
      <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
        <iframe
          src={`${REACT_APP_BASE_API_URI}workflow/${selectedProject.workflowId}`}
          title="workflows"
          style={{
            width: '100%',
            height: '100%',
            border: 'none', 
          }}
          allowFullScreen
        />
    </div>
    ),
  };

  const handleRightClick = (e, group) => {
    e.preventDefault();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      group,
    });
  };

  
  const handleCancel = () => {
    setIsModalVisible(false);
    setFile(null);
    setFileName(null);
    setProjectName('');
    projectForm.resetFields();
  };

  const handleFileChange = async (info) => {
    const { file } = info;

    if (!file || !file.originFileObj) {
      console.error('File object or originFileObj is undefined.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file.originFileObj);

    let result = await uploadProjectFile(formData);

    if (result && result.success) {
      setFile(file.originFileObj); 
      setFileName(result.filename);
      projectForm.setFieldsValue({ fileName: result.filename });
    } else {
      console.error("File upload failed");
    }

  };
  
  const fetchClientList = async () => {
    let result = await getClients();
    setClientList(result);
    if (Array.isArray(result) && result[0].hasOwnProperty('clientId') ){
        fetchClientInfo(result[0].clientId);
    }
  }

  const fetchClientInfo = async(clienId) => {
    setContentLoading(true);
    try {
    let result = await getClientInfo(clienId);
    setClientData(result);
    if (result && result.groups) {
      const extractedGroups = result.groups.map(({ groupId, groupName }) => ({groupId, groupName}));
      setGroups(extractedGroups);
    }
  }catch(error){
      console.error(error);
    }finally{
      setContentLoading(false);
    }

  }

  const handleClientMenuClick = (e) => {
    const selectedClient = e;
    setSelectedClient(selectedClient);
    setSelectedGroup(null);
    setSelectedProject(null); 
    setMenuItems([]); 
    setSelectedMenuItem([]);
    fetchClientInfo(selectedClient);
  };

  // Add new group
  const handleAddGroup = async () => {
    if (!addGroupName.trim()) {
      console.error("Group name cannot be empty!");
      return;
    }
    let result = await processAddGroup(selectedClient, addGroupName);
    if (result && result.success) {
      fetchClientInfo(selectedClient);
      setAddGroupName("");
      message.success("Group added successfully!");
    }else{
      message.error("Failed to add group.")
    }
  };

  // Delete group
  const handleDeleteGroup = (groupId) => {
    setGroups(groups.filter((group) => group.groupId !== groupId));
    message.success("Group deleted successfully!");
  };

  // Start editing group
  const handleEditGroup = (record) => {
    setEditingGroup(record);
    groupForm.setFieldsValue({ groupName: record.groupName });
  };

  // Save edited group
  const handleSaveEdit = async (values) => {
      console.log("Form Values: ", values); 
      try {
        const response = await processUpdateGroup(editingGroup.groupId, values.groupName);
        if (response.success) {
          fetchClientInfo(selectedClient);
          setEditingGroup(null);
          message.success("Group updated successfully!");
        } else {
          message.error("Failed to update group.");
        }
      } catch (error) {
        console.error(error);
        message.error("An error occurred while updating the group.");
      }
  };

  const groupColumns = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
      render: (text, record) =>
        editingGroup?.groupId === record.groupId ? (
          <Form form={groupForm} onFinish={handleSaveEdit}>
            <Form.Item
              name="groupName"
              initialValue={editingGroup?.groupName}
              rules={[{ required: true, message: "Group name is required!" }]}
              style={{ margin: 0 }}
            >
              <Input />
            </Form.Item>
          </Form>
        ) : (
          text
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        editingGroup?.groupId === record.groupId ? (
          <Space>
            {/* Use form submission */}
            <Button type="primary" onClick={() => groupForm.submit()}>
              Save
            </Button>
            <Button onClick={() => setEditingGroup(null)}>Cancel</Button>
          </Space>
        ) : (
          <Space>
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditGroup(record)}
            />
            <Button
              icon={<DeleteOutlined />}
              danger
              disabled
              onClick={() => handleDeleteGroup(record.groupId)}
            />
          </Space>
        ),
    },
  ];  

  const handleGroupSelect = (groupId) => {
    const group = clientData.groups.find((g) => g.groupId === groupId);
    setSelectedGroup(group);
  };

  const renderContextMenu = () => {
    if (contextMenu.visible) {
      return (
        <Menu style={{ top: contextMenu.y, left: contextMenu.x, position: 'absolute' }}
          className='add-project-context-menu'
          onClick={handleAddProject}>
          <Menu.Item key="1" style={{fontWeight : '600'}}>Add Project</Menu.Item>
        </Menu>
      );
    }
    return null;
  };

  const handleProjectSelect = (projectId) => {
    setContentLoading(true);
    setBatchVisible(false);
    setSelectedRowKeys([]);
    const group = clientData.groups.find((g) =>g.projects.some((p) => p.projectId === projectId));
    const project = group.projects.find((p) => p.projectId === projectId);
    console.log(project);
    setSelectedProject(project);
    setSelectedProjectId(projectId);
    setContentLoading(false);
  };

  const handleNavMenuClick = (e) =>{
    const menuSelected = e.key;
    const menu = menuItems.find((p)=> menuSelected === p.key);
    setSelectedMenuItem(menu);
  }

  const handleAddProject = () => {
    setContextMenu({ ...contextMenu, visible: false });
    setIsModalVisible(true);
  };

  const handleSaveAddProject = async (val) => {
    const contextGroupId = contextMenu.group.groupId;
    const {projectName, fileName} = val;
    try{
        let result = await processAddProject(projectName, fileName, contextGroupId);
        if(result && result.success){
          fetchClientInfo(selectedClient);
          handleCancel();
          message.success('Project added successfully');
        }else{
          message.error('Failed to add project');
        }
    }catch(error){
      console.error(error);
    }finally{

    }
  }

  const customExpandIcon = ({ isActive }) => {
    return isActive ? (
        <MinusOutlined style={{ fontSize: 14}}/>
    ) : (
        <PlusOutlined style={{ fontSize: 14}}/>
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={250} style={{ backgroundColor: "rgb(51, 61, 81)", padding: "15px 8px", overflowY: 'auto', maxHeight: '100vh' }}>
        {clientData ? (
          <>  
            <Select onChange={handleClientMenuClick} className="custom-client-select" value={selectedClient}>
              {clientList && clientList.map((client) =>
                    <Option value={client.clientId}>{client.clientName}</Option>
                )
              }
            </Select>

            <Button type='primary' style={{width : '100%'}}  onClick={()=>setModalVisible(true)} icon={<EditFilled />}>Manage Groups</Button>

            {selectedClient &&  clientData.groups && clientData.groups.length > 0 &&(
                <div style ={{marginTop :'10px'}}>
                    <Collapse accordion onChange={(key) => handleGroupSelect(parseInt(key, 10))}
                      className="custom-collapse">

                        {clientData.groups.map((group) => (
                        <Panel header={group.groupName} key={group.groupId.toString()} 
                        style={{background : 'rgb(240, 240, 240)'}}
                        onContextMenu={(e) => handleRightClick(e, group)}>
                            
                            {group.projects.map((project) => (
                                <Button 
                                  key={project.projectId} type=''
                                  className= {selectedProject?.projectId === project.projectId ? 'project-btn' : 'project-btn-selected'}
                                  onClick={() => handleProjectSelect(project.projectId)}
                                >
                                    {project.projectName}
                                </Button>
                            ))}
                        </Panel>
                        ))}

                    </Collapse>
              </div>
            )}
          </>
        ) : (
          <LoadingOutlined style={{display : 'flex',justifyContent: 'center', fontSize : '25px', color : 'Silver'}}></LoadingOutlined>
        )}
      </Sider>

      <Layout>
        <Layout.Header
         style={{ backgroundColor: "rgb(51, 61, 81)", position : 'sticky',
            display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            padding: '0 5px', color: 'rgb(0 0 0)', fontSize: '14px', height: '45px', zIndex: 1000,
          }}
        >
          <div className="demo-logo" style={{ color: 'rgb(240, 240, 240)', fontWeight: 'bold', fontSize: '17px' }}>
              {selectedProject?.projectName}
          </div>

          {/* Navbar Menu Styling */}
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[selectedMenuItem?.key]}
            items={menuItems.map(item => ({
              key: item.key,
              label: item.label,
              onClick: (e) => handleNavMenuClick(e),
            }))}
            className='custom-nav-menu'
            style={{backgroundColor: "rgb(51, 61, 81)",flex: 1,display: "flex",minWidth: 0,justifyContent: 'flex-end',
              height: "45px", lineHeight: "45px", padding: 0, borderBottom: "none",
            }}
          />
        </Layout.Header>

        {/* Content Area */}
        <Content style={{ margin: "5px", padding: "10px", background: "#fff", minHeight: "80vh" }}>
          {selectedMenuItem && (
            <div key={selectedMenuItem.key}>
              {contentLoading ? <span style={{fontSize : "14px", fontWeight :"bold"}}>Loading...</span> : selectedMenuItem?.content}
            </div>
          )}
        </Content>
      </Layout>

      <Modal title="Manage Groups" open={modalVisible} onCancel={()=>setModalVisible(false)}
        footer={null} width={600}>
      <Space style={{ marginBottom: 16 }}>
        <Input placeholder="Enter group name"
          value={addGroupName}
          onChange={(e) => setAddGroupName(e.target.value)}
          style={{ width: 200 }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddGroup}
        >
          Add
        </Button>
      </Space>
      <Table
        dataSource={groups}
        columns={groupColumns}
        rowKey="groupId"
        pagination={{ pageSize: 10 }}
        bordered
        className='manage-group-table'
      />
    </Modal>


<Modal
  title="Add New Project"
  open={isModalVisible}
  onCancel={handleCancel}
  footer={null}
>
  <Form layout="vertical" form={projectForm}
  onFinish={handleSaveAddProject}
  >
    <Form.Item label="Project Name"
      name="projectName"
      rules={[{ required: true , message: "Enter a Project Name"}]}>
      <Input
        value={projectName}
        onChange={(e) => setProjectName(e.target.value)}
        placeholder="Enter Project Name"
      />
    </Form.Item>

    <Form.Item label="Upload File" name="fileName"
     rules={[{ required: true, message: "Upload a file"}]}
    >
      <Upload
        name="file"
        accept=".xlsx,.xls"
        onChange={handleFileChange}
        showUploadList={false}
        customRequest={() => {}}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
      {fileName && (
        <div style={{ marginTop: '10px' }}>
          <strong>Selected File:</strong> {fileName}
        </div>
      )}
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit">
        Add Project
      </Button>
    </Form.Item>
  </Form>
</Modal>

{contextMenu.visible && renderContextMenu()}

    </Layout>
  );
};

export default WorkFlowManagement;
